import React from 'react';

import { useTranslation } from 'react-i18next';

import { calculatePrice } from 'utils/estimationCalculations';

import { CalculationsWrapper, SectionTitle } from './styled';

const SixthStep = ({ formValues }) => {
  const { t } = useTranslation('translation');

  const [price, kwp, storageKwh, priceStorage] = calculatePrice(
    formValues.roof_are || formValues.roof_type,
    formValues.panel_type,
    formValues.inverter,
    formValues.battery === 'yes'
  );

  return (
    <CalculationsWrapper>
      <SectionTitle>
        {t('landing.estimation_form.saving_potential')}
        <span>{`${(142 * kwp).toFixed()} €`}</span>
      </SectionTitle>
      <p>{t('landing.estimation_form.what_you_could_save')}</p>
      <div>
        <p>{t('landing.estimation_form.kwp_size')}</p>
        <p className="price">{`${kwp} kWp`}</p>
      </div>
      <div>
        <p>{t('landing.estimation_form.storage')}</p>
        <p className="price">{`${storageKwh} kWh`}</p>
      </div>
      <div>
        <p>{t('landing.estimation_form.kwp_investment')}</p>
        <p className="price">{`${kwp ? ((price - priceStorage) / kwp).toFixed() : 0} €`}</p>
      </div>
      <div>
        <p>{t('landing.estimation_form.total_investment')}</p>
        <p className="price">{`${price} €`}</p>
      </div>
      <p className="disclaimer">
        <span>{t('landing.estimation_form.thank_you')}</span>
        {t('landing.estimation_form.switching_solar_energy')}
      </p>
    </CalculationsWrapper>
  );
};

export default SixthStep;
